import React from "react"
import { Link } from "gatsby"

import Image from "../components/image"
import SEO from "../components/seo"
import SeasonalsList from "../components/seasonalsList"
import CurrentDate from "../components/currentDate"

import fruits from "../db/fruits"
import vegetables from "../db/vegetables"

const IndexPage = () => (
  <div class="index-page">
    <SEO title="seasonal" />
    <h1 class="seasonal-header">seasonal.fyi</h1>
    <CurrentDate />
    <br/>
    <h2>Fruits in season</h2>
    <SeasonalsList produceData={fruits}/>
    <br/>
    <h2>Vegetables in season</h2>
    <SeasonalsList produceData={vegetables}/>
  </div>
)

export default IndexPage

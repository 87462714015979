const vegetables = [
  {
    name: 'asparagus',
    months: [3, 4, 5],
  },
  {
    name: 'avocados',
    months: [1, 2, 3, 4, 5, 6, 7, 8, 12],
  },
  {
    name: 'beets',
    months: [1, 2, 6, 7, 8, 9, 10, 11, 12],
  },
  {
    name: 'bell peppers',
    months: [6, 7, 8, 9, 10, 11],
  },
  {
    name: 'broccoli',
    months: [3, 4, 5, 9, 10, 11],
  },
  {
    name: 'brussel sprouts',
    months: [1, 2, 9, 10, 11, 12],
  },
  {
    name: 'cauliflower',
    months: [9, 10, 11],
  },
  {
    name: 'cabbage',
    months: [1, 2, 3, 4, 5, 9, 10, 11, 12],
  },
  {
    name: 'carrots',
    months: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
  },
  {
    name: 'celery',
    months: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
  },
  {
    name: 'collard greens',
    months: [1, 2, 3, 4, 5, 9, 10, 11, 12],
  },
  {
    name: 'corn',
    months: [6, 7, 8],
  },
  {
    name: 'cucumbers',
    months: [6, 7, 8],
  },
  {
    name: 'eggplant',
    months: [6, 7, 8],
  },
  {
    name: 'garlic',
    months: [3, 4, 5, 6, 7, 8, 9, 10, 11],
  },
  {
    name: 'ginger',
    months: [9, 10, 11],
  },
  {
    name: 'green beans',
    months: [6, 7, 8, 9, 10, 11],
  },
  {
    name: 'kale',
    months: [1, 2, 3, 4, 5, 9, 10, 11, 12],
  },
  {
    name: 'lettuce',
    months: [3, 4, 5, 9, 10, 11],
  },
  {
    name: 'lima beans',
    months: [6, 7, 8],
  },
  {
    name: 'mushrooms',
    months: [3, 4, 5, 9, 10, 11],
  },
  {
    name: 'okra',
    months: [6, 7, 8],
  },
  {
    name: 'onions',
    months: [1, 2, 3, 4, 5, 9, 10, 11, 12],
  },
  {
    name: 'parsnips',
    months: [1, 2, 9, 10, 11, 12],
  },
  {
    name: 'peas',
    months: [3, 4, 5, 9, 10, 11],
  },
  {
    name: 'pumpkin',
    months: [1, 2, 9, 10, 11, 12],
  },
  {
    name: 'radishes',
    months: [3, 4, 5, 9, 10, 11],
  },
  {
    name: 'rhubarb',
    months: [3, 4, 5],
  },
  {
    name: 'spinach',
    months: [3, 4, 5, 9, 10, 11],
  },
  {
    name: 'summer squash',
    months: [6, 7, 8],
  },
  {
    name: 'sweet potates',
    months: [1, 2, 9, 10, 11, 12],
  },
  {
    name: 'swiss chard',
    months: [1, 2, 3, 4, 5, 9, 10, 11, 12],
  },
  {
    name: 'tomatillos',
    months: [6, 7, 8],
  },
  {
    name: 'tomatoes',
    months: [6, 7, 8],
  },
  {
    name: 'turnips',
    months: [1, 2, 3, 4, 5, 9, 10, 11, 12],
  },
  {
    name: 'winter squash',
    months: [1, 2, 9, 10, 11, 12],
  },
  {
    name: 'zucchini',
    months: [6, 7, 8],
  },
];

export default vegetables;

/**
 * SeasonalsList component that queries for data with
 */

import React from "react"
//import fruits from "../db/fruits"

var d = new Date();
var currentMonth = d.getMonth();
currentMonth = currentMonth + 1;

function SeasonalsList(props) {
  var currentProduce = props.produceData.filter(function(produce) {
    const months = produce.months;
    if(months.includes(currentMonth)) {
      return produce; 
    }
  });
  const produceItems = currentProduce.map(function (item, key) {
    var months = item.months;
    var currentMonthIndex = months.indexOf(currentMonth);
    var nextMonthIndex = currentMonthIndex + 1;
    if(currentMonthIndex == months.length - 1) {
      nextMonthIndex = 0;
    }
    var nextMonth = months[nextMonthIndex];
    var monthDiff = nextMonth - currentMonth;
    var availableNextMonthStatement;
    if(monthDiff !== 1) {
      availableNextMonthStatement = (
        <span class="out-of-season">
          out of season next month
        </span>
      )
    }
    return (
      <div class="produce-item" key={key}>
        {item.name} {availableNextMonthStatement}
      </div>
    )
  });
  return (
    <div>
      {produceItems}
    </div>
  )
}


export default SeasonalsList

const fruits = [
  {
    name: 'apples',
    months: [8, 9, 10, 11],
  },
  {
    name: 'apricots',
    months: [5, 6, 7],
  },
  {
    name: 'apriums',
    months: [5, 6],
  },
  {
    name: 'asian pears',
    months: [9, 10, 11],
  },
  {
    name: 'blackberries',
    months: [5, 6, 7, 8, 9, 10],
  },
  {
    name: 'blueberries',
    months: [5, 6, 7, 8],
  },
  {
    name: 'boysenberries',
    months: [6, 7],
  },
  {
    name: 'cactus pears',
    months: [5, 6, 7, 8, 9, 10, 11],
  },
  {
    name: 'blueberries',
    months: [5, 6, 7, 8],
  },
  {
    name: 'cherimoyas',
    months: [1, 2, 3, 4, 5, 11, 12],
  },
  {
    name: 'cherries',
    months: [4, 5, 6, 7],
  },
  {
    name: 'chestnuts',
    months: [9],
  },
  {
    name: 'citron',
    months: [1, 11, 12],
  },
  {
    name: 'dates',
    months: [9, 10, 11],
  },
  {
    name: 'feijoas',
    months: [11, 12],
  },
  {
    name: 'figs',
    months: [6, 7, 9, 10],
  },
  {
    name: 'grapefruit',
    months: [1, 2, 3, 4, 12],
  },
  {
    name: 'grapes',
    months: [7, 8, 9, 10, 11],
  },
  {
    name: 'guavas',
    months: [2, 3, 4], 
  },
  {
    name: 'jujubes',
    months: [9, 10],
  },
  {
    name: 'kiwi',
    months: [9, 10, 11, 12],
  },
  {
    name: 'kumquats',
    months: [1, 2, 3, 4],
  },
  {
    name: 'lemons',
    months: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
  },
  {
    name: 'limes',
    months: [1, 2, 3, 4, 10, 11, 12],
  },
  {
    name: 'loquats',
    months: [5, 6],
  },
  {
    name: 'mandarins',
    months: [1, 2, 3, 4, 5, 11, 12],
  },
  {
    name: 'melons',
    months: [6, 7, 8, 9, 10],
  },
  {
    name: 'mulberries',
    months: [7, 8],
  },
  {
    name: 'nectarines',
    months: [5, 6, 7, 8, 9, 10],
  },
  {
    name: 'olives',
    months: [1, 9, 10, 11, 12],
  },
  {
    name: 'oranges',
    months: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
  },
  {
    name: 'peaches',
    months: [5, 6, 7, 8, 9, 10],
  },
  {
    name: 'pears',
    months: [8, 9, 10, 11],
  },
  {
    name: 'pecans',
    months: [10],
  },
  {
    name: 'persimmons',
    months: [9, 10, 11],
  },
  {
    name: 'pistachios',
    months: [9, 10, 11],
  },
  {
    name: 'plums',
    months: [5, 6, 7, 8, 9, 10, 11],
  },
  {
    name: 'pluots',
    months: [5, 6, 7, 8, 9],
  },
  {
    name: 'pomegranates',
    months: [9, 10, 11, 12],
  },
  {
    name: 'pomelos',
    months: [1, 2, 3, 4, 11, 12],
  },
  {
    name: 'quince',
    months: [9, 10, 11],
  },
  {
    name: 'raspberries',
    months: [5, 6, 7, 8, 9, 10, 11],
  },
  {
    name: 'rhubarb',
    months: [4, 5, 6, 8],
  },
  {
    name: 'strawberries',
    months: [3, 4, 5, 6, 7, 8, 9, 10, 11]
  },
  {
    name: 'tayberries',
    months: [6, 7, 8]
  },
  {
    name: 'walnuts',
    months: [10],
  },
];

export default fruits;

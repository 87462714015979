/**
 * CurrentDate component that queries for data with
 */

import React from "react"
import moment from 'moment'

function CurrentDate() {
  var date = moment().format('LL');
  return (
    <div>
      Today is {date} 
    </div>
  )
}

export default CurrentDate
